const simplifiedMenuES = {
  'sm.filter_by': '[ES] Filter By',
  'sm.all_menu_groups': '[ES] All Menu Groups',
  'sm.no_menu_groups': '[ES] No Menu Groups',
  'sm.menu_groups_count': '[ES] {{count}} Menu Groups',
  /* TAXES - START */
  'simplified_menu.taxes.add': '[ES]Create Tax',
  'simplified_menu.taxes.edit': '[ES]Edit Tax',
  'simplified_menu.taxes.back': '[ES]Back To Taxes',
  'simplified_menu.taxes.details': '[ES]Tax Details',

  'simplified_menu.taxes.name': '[ES]Tax Name',
  'simplified_menu.taxes.name.required': '[ES]Tax name is required',

  'simplified_menu.taxes.tax_percentage': '[ES]Tax Percentage',
  'simplified_menu.taxes.tax_percentage.required': '[ES]Tax percentage is required',
  'simplified_menu.taxes.tax_percentage.general_error':
    '[ES]Tax percentage must be between 0.001 and 100, up to three decimal places and no % symbol',

  'simplified_menu.taxes.tax_number': '[ES]Tax Number',
  'simplified_menu.taxes.tax_number.required': '[ES]Tax number is required',
  'simplified_menu.taxes.tax_number.input_helper':
    '[ES]Recorded for tax reporting purposes. Can enter alphanumeric text.',

  'simplified_menu.taxes.location': '[ES]Location',
  'simplified_menu.taxes.location.input_helper':
    '[ES]To change this location, please go back to the dashboard and select',

  'simplified_menu.taxes.advanced_details': '[ES]Advanced Details',
  'simplified_menu.taxes.total_amount_items':
    '[ES]When the total amount for all items is under or the same as',
  'simplified_menu.taxes.the_tax_rate_is': '[ES]the tax rate is',
  'simplified_menu.taxes.exclude_from': '[ES]Exclude this tax from',
  'simplified_menu.taxes.exclusions.0': '[ES]Online Order',
  'simplified_menu.taxes.exclusions.1': '[ES]In Venue',
  'simplified_menu.taxes.exclusions.2': '[ES]Bar Tab',

  'simplified_menu.taxes.create.success.message':
    '[ES]“{{name}}” has been successfully created for {{venueName}}!',
  'simplified_menu.taxes.update.success.message':
    '[ES]“{{name}}” has been successfully updated for {{venueName}}!',
  'sm.amount': '[ES] Amount',
  'sm.rate': '[ES] Rate',
  /* TAXES - END */
  'sm.assign_to': '[ES] Assign to',
  'sm.select_all': '[ES] Select All',
  'sm.you_are_viewing': '[ES] You are viewing:',
  'sm.search': '[ES] Search',
  'sm.no_results_found': '[ES] No results found.',
  'sm.empty_sales_category_img_description':
    '[ES] An image of a donut graph showing how different sales categories contribute to sales',
  'sm.edit_menu': '[ES] Edit Menu',
  'sm.taxes': '[ES] Taxes',
  'sm.search_taxes': '[ES] Search taxes',
  'sm.create_tax': '[ES] Create Tax',
  'sm.percentage': '[ES] Percentage',
  'sm.location': '[ES] Location',
  'sm.advanced_settings': '[ES] Advanced Settings',
  'sm.yes': '[ES] Yes',
  'sm.no': '[ES] No',
  'sm.tax': '[ES] tax',
  'sm.cancel': '[ES] Cancel',
  'sm.delete': '[ES] Delete',
  'sm.delete_tax': '[ES] Delete Tax',
  'sm.delete_tax.success': '[ES] {{label}} has been deleted from Taxes',
  'sm.discount.delete_modal_title':
    "[ES] Are you sure you want to delete the discount named '{{label}}'?",
  'sm.tax.delete_modal_title': "[ES]Are you sure you want to delete the tax named '{{label}}'?",
  'sm.no_taxes_yet': "[ES] You don't have any taxes yet.",
  'sm.taxes_explanation_1':
    '[ES] Taxes are added to the price of menu items. When setting up your taxes, you can choose to add advanced tax rules. These rules allow you to customize how taxes are applied to the final bill.',
  'sm.taxes_explanation_2':
    "[ES] For instance, you can set up a rule where Tax A is only applied if the total bill is $10 or more. It's important to note that tax rates may vary depending on the geographic location of your venue.",
  'sm.tax_example': '[ES] Tax Example',
  'sm.taxes.name.helper': '[ES] Your tax name can be displayed to your staff and customers.',
  'sm.taxes.tax_number': '[ES] Tax Number',
  'sm.all_taxes': '[ES] All Taxes',
  'sm.no_taxes': '[ES] No Taxes',
  'sm.taxes_count': '[ES] {{count}} Taxes',
  'sm.platforms': '[ES] Platforms',
  'sm.schedule': '[ES] Schedule',
  'sm.schedule_description.menu': '[ES] Choose when your menu is offered.',
  'sm.schedule_description.menu_group': '[ES] Choose when your menu group is offered.',
  'sm.availability': '[ES] Availability',
  'sm.name': '[ES] Name',
  'sm.price.min_error': '[ES] Price cannot be a negative number',
  'sm.two_decimal_error': '[ES] {{field}} can only have up to 2 decimal places.',
  'sm.menu_item.name': 'Menu Item Name',
  'sm.menu_item.name.helper': 'Your menu item name can be displayed to your staff and customers.',
  'sm.menu_item.short_name': 'Short Name',
  'sm.menu_item.short_name.helper':
    'Short name will be displayed on the order ticket, receipt, and reports.',
  'sm.menu_item.description': 'Description',
  'sm.menu_item.description.helper':
    'This description can be displayed to your staff and customers.',
  'sm.menu_item.upc': '[ES] UPC Code',
  'sm.menu_item.upc.helper': '[ES] Scan or manually input a barcode for this menu item',
  'sm.menu_item.upc.scan_code': '[ES] Scan Code',
  'sm.menu_item.upc.scan_upc_code': '[ES] Scan UPC Code',
  'sm.menu_item.price': 'Price',
  'sm.menu_item.price.helper': 'Assign a price to your menu item.',
  'sm.menu_item.open_price.helper':
    'If Open Price is selected,the server must enter a price each time that item is ordered. Open-priced items will not be offered via Online Ordering.',
  'sm.menu_item.taxes.helper': 'Associated tax(s) to a menu item will also appear on the receipt.',
  'sm.menu_item.cost': 'Total cost of ingredients',
  'sm.menu_item.cost.helper':
    'How much does this cost to make? Ingredient(s) cost will be used for your reporting.',
  'sm.menu_item.menu_group.helper':
    'Attach this menu item to a menu group. For example, add Kale Salad to Salads.',
  'sm.menu_item.sales_category.helper':
    'If your menu item does not belong in the Uncategorized sales category, you can attach your menu item to another existing sales category.',
  'sm.menu_item.sales_category.choose': '[ES] Choose a Sales Category:',
  'sm.menu_item.sales_category.bulk_update':
    '[ES] Sales category changes will apply to all selected items.',
  'sm.menu_item.prep_stations.placeholder': 'Search existing prep stations',
  'sm.menu_item.prep_stations.note':
    '[ES] This functionality will only add prep stations to your menu items. It does not replace or remove the prep stations attached to the menu item.',
  'sm.menu_item.prep_stations.message': '[ES] Add or remove prep stations for the selected items.',
  'sm.menu_items.prep_station.add': '[ES] Add Prep Station',
  'sm.menu_items.prep_station.remove': '[ES] Remove Prep Station',
  'sm.menu_items.only_one_filter': '[ES] Only 1 filter may be used at once',
  'sm.menu_items.no_filtered_items_found': '[ES] You have no menu items with the selected criteria',
  'sm.menu_items.no_filtered_menu_items_data_alt': '[ES] TouchBistro no filtered menu items image',
  'sm.menu_item.color': '[ES]Color',
  'sm.menu_item.colors': '[ES]Colors',
  'sm.menu_item.bulk_colors_subtitle': '[ES]Color changes will apply to all selected items.',
  'sm.menu_item.bulk_colors_updated': '[ES]Color has been updated!',
  'sm.menu_items.menu_group.bulk_attach': 'Attach Menu Groups',
  'sm.menu_items.menu_group.bulk_attach_subtitle':
    '[ES] Select menu group(s) to attach to your menu items.',
  'sm.menu_items.menu_group.select': '[ES] Select Menu Group(s)',
  'sm.menu_items.menu_group.updated': '[ES] Menu Groups updated!',
  'sm.background_color': '[ES] Background Color',
  'sm.course': '[ES] Course',
  'sm.default_course': '[ES] Default Course',
  'sm.course_description.menu_item':
    '[ES] When you assign a course to a menu item, it will be displayed on your POS. This helps differentiate the menu item from the default course assigned to the Menu Group.',
  'sm.background_color_description.menu_item':
    '[ES] You can assign color to menu items, displaying it on connected products like your Point of Sale (POS) system. This helps your staff easily recognize menu items while taking orders.',
  'sm.background_color_description.menu_group':
    '[ES] The selected menu group color will apply to associated menu items and will appear on connected products, such as your POS.',
  'sm.course_description.menu_group':
    '[ES] Assigning a default course to a Menu group will apply to associated menu items and be displayed on connected products, such as your POS.',
  'sm.available_for': '[ES] Available For',
  'sm.available_for_description.menu': '[ES] Pick where your menu is offered',
  'sm.available_for_description.menu_item': '[ES] Pick where your menu item is offered',
  'sm.available_for_note.menu_item':
    '[ES] Note: You can use the above options to manage availability for seasonal or limited menu items',
  'sm.available_for_description.modifier_group': '[ES] Pick where your modifier group is offered',
  'sm.description': '[ES] Description',
  'sm.pricing': '[ES] Pricing',
  'sm.price': '[ES] Price',
  'sm.price_value': 'Price {{price}}',
  'sm.open_price': '[ES] Open Price Item',
  'sm.search_sales_categories': '[ES] Search sales categories',
  'sm.search_menu_items': '[ES] Search menu items',
  'sm.menu_name': '[ES] Menu Name',
  'sm.menu_group_name': '[ES] Menu Group Name',
  'sm.menu_name.description': '[ES] Your menu name will be displayed on your connected products.',
  'sm.menu_description': '[ES] Menu Description',
  'sm.menu_group': '[ES] Menu Group',
  'sm.menu_groups': '[ES] Menu Groups',
  'sm.menu_group.subheader_1':
    '[ES] When you add menu groups to your menu, it automatically includes the menu items that are associated with those groups.',
  'sm.menu_group.subheader_2':
    '[ES] For instance, if you add the "Salads" menu group to your menu, it will include menu items like "Kale Salad" and "Harvest Salad" as well. This makes it easier to manage and display menu items.',
  'sm.inventory_settings': '[ES] Inventory Settings',
  'sm.plu_code': '[ES] PLU Code',
  'sm.plu_code_description': '[ES] Product look up code',
  'sm.out_of_stock': '[ES] Out of stock',
  'sm.back_in_stock': '[ES] Back in stock',
  'sm.item_out_of_stock': '[ES] Item out of stock',
  'sm.menu_item_scheduling_description':
    '[ES] Choose what hours this menu item will be available for',
  'sm.item_listed_out_of_stock': '[ES] {{label}} has been listed as out of stock',
  'sm.item_listed_back_in_stock': '[ES] {{label}} has been listed as back in stock',
  'sm.is_taxable': '[ES] Is Taxable',
  'sm.item_successfully_created': '[ES] {{item}} has been successfully created',
  'sm.invalid_date': '[ES] Invalid date',
  'sm.never_published': '[ES] Never published',
  'sm.no_taxes_available': '[ES] No taxes available',
  'sm.menu.printer_groups': '[ES]Prep Stations',
  'sm.printer_group.not_available': '[ES]No prep stations available',
  'sm.printer_group.choose': '[ES]Choose a Prep Station:',
  'sm.printer_group.updated': '[ES]Prep Stations updated!',
  'sm.printer_group.unassigned': '[ES]No prep station',
  'sm.menu_items.taxes.bulk_update':
    '[ES] Tax(es) changes will add/remove to the existing tax setup for all selected items.',
  'sm.menu_items.taxes.options': '[ES] Tax Options',
  'sm.menu_items.taxes.add': '[ES] Add Tax(es)',
  'sm.menu_items.taxes.remove': '[ES] Remove Tax(es)',
  'sm.menu.printer_group.attach_group_description':
    '[ES]Where would you like this menu item to print? Ex. bar printer or kitchen',
  'sm.max_3_taxes':
    '[ES] No more than three taxes can be created. Please delete a tax that is no longer required before creating a new one.',
  'sm.none': '[ES] None',
  'sm.your_venue': '[ES] your venue',
  'sm.manager_approval_required': '[ES] Requires managers approval to order',
  'sm.this_item_gift_card': '[ES] This item is a gift card',
  'sm.this_item_alcohol': '[ES] This item contains Alcohol',
  'sm.this_item_is_returnable': '[ES] This Item can be returned',
  'sm.this_item_is_returnable.helper':
    '[ES] Check this if you need the ability to refund an item without the original bill. For example, you can do this for Bottle or Keg Deposits.',
  'sm.this_item_is_returnable.confirmation.title': '[ES] Modifier prices will update',
  'sm.this_item_is_returnable.confirmation.submit': '[ES] Enable Returns',
  'sm.this_item_is_returnable.confirmation.body':
    "[ES] Enabling {{label}} to be returned will update any instances it's used as a menu item modifier and change its modifier price to {{price}}. Are you sure you want to make this item returnable?",
  'sm.this_item_is_returnable.confirmation.body.backup_item_label': '[ES] this item',
  'sm.this_item_is_returnable.confirmation.body.backup_price_label': "[ES] the item's price",
  'sm.publish.welcome_screen.title': '[ES] Update Changes to Your POS and Online Ordering Menu!',
  'sm.publish.welcome_screen.explanation_1':
    '[ES] When you hit save within menu, your updates are not yet live. Here you will manage all your saved item and make them go live to the POS or Online Ordering by publishing them.',
  'sm.publish.welcome_screen.explanation_2':
    "[ES] This page will remain empty until you have created or updated something for your menu. Come back and publish when you're ready!",
  'sm.publish.up_to_date': `[ES] Everything is up to date at {{venue}}`,
  'sm.publish.search_placeholder': '[ES] Search...',
  'sm.publishing_successful': '[ES] Menu successfully published',
  'sm.publishing_successful_venue': '[ES] {{venueName}} menu was successfully published',
  'sm.publishing_failed': '[ES] Menu publishing failed for {{venueName}}',
  'sm.details': '[ES] Details',
  'sm.schedule_publishing': '[ES] Schedule Publishing',
  'sm.add_edit_scheduled_publication': '[ES] Add/Edit Scheduled Publication',
  'sm.schedule_publication': '[ES] Schedule Publication',
  'sm.schedule_publication_message':
    'Would you like to schedule <1>all</1> your unpublished items for publishing?',
  'sm.schedule_publication_banner':
    '[ES] Your location is set to publish all items on {{publishAt}}. You have the option to <1>edit</1> or <2>delete</2> the scheduled publication. Additionally, you can publish all your changes immediately by clicking "Publish All" at the top of the page.',
  'sm.scheduled_publication_venue_success': '[ES] Menu publication scheduled successfully',
  'sm.scheduled_publication_venues_success': '[ES] Menu publications scheduled successfully',
  'sm.scheduled_publication_err_invalid_publishat_error':
    '[ES] Schedule time is invalid, please select a valid time',
  'sm.scheduled_publication_future_publishat_error':
    '[ES] Scheduled time cannot be more than 30 days in the future',
  'sm.scheduled_publication_past_publishat_error': '[ES] Scheduled time cannot be in the past',
  'sm.scheduled_publication_timezone_missing':
    'Please setup your timezone in order to schedule a menu publication',
  'sm.scheduled_publication_venues_missing_timezone':
    '[ES] Please make sure the timezone is set for the following venues in order to schedule a menu publication:\n{{venues}}',
  'sm.scheduled_publication.delete_modal_title':
    '[ES] Are you sure you want to delete your scheduled publication?',
  'sm.delete_scheduled_publication': '[ES] Delete Scheduled Publication',
  'sm.delete_scheduled_publication.success': '[ES] Schedule Publication has been deleted',
  'sm.delete_scheduled_publications.success': '[ES] Schedule Publications have been deleted',
  'sm.scheduled_publication': '[ES] Scheduled Publication',
  'sm.type': '[ES] Type',
  'sm.last_saved': '[ES] Last Saved',
  'sm.last_published': '[ES] Last Published',
  'sm.pending': '[ES] {{numberOfUpdates}} Pending',
  'sm.modified_by': '[ES] By {{author}}',
  'sm.modifier_group.assign_to.helper': '[ES] Attach this modifier group to an existing menu item.',
  'sm.modifier_group.assign_to.input.helper': '[ES] Add Modifier Items',
  'sm.modifier_group.modifier.instruction.name': '[ES] Kitchen Instruction Name',
  'sm.modifier_group.modifier.new.name.input_helper':
    '[ES] Your Kitchen Instruction Name will be displayed to your staff.',
  'sm.modifier_group.updated': '[ES]Modifier Groups have been updated!',
  'sm.modifier_group.attach': '[ES]Attach Modifier Groups',
  'sm.modifier_group.subtitle': '[ES]Select modifier group(s) to attach to your menu item.',
  'sm.sales_category.name': '[ES] Sales Category Name',
  'sm.taxes.updated': '[ES] Tax updated!',
  'sm.sales_categories.updated': '[ES] Sales Category updated!',
  'sm.all_sales_categories': '[ES] All Sales Categories',
  'sm.sales_categories_count': '[ES] {{count}} Sales Categories',
  'sm.uncategorized': '[ES] Uncategorized',
  'sm.import_menu': '[ES] Import Menu',
  'sm.menu_import_success':
    '[ES] Your request to import the menu was submitted. Please check back in a short while to view the menu. Depending on the size of the menu, it could take longer than a minute to process the uploaded menu.',
  'sm.menu_export_success':
    '[ES] Menu export successfully requested. Please check your email in a few moments for the export.',
  'sm.importing': '[ES] Importing..',
  'sm.export_menu': '[ES] Export Menu',
  'sm.exporting': '[ES] Exporting..',
  'sm.import_menu_get_template': '[ES] Get the latest menu template here',
  'sm.drag_and_drop_helper_text': '[ES] Drag and drop to upload your file or click to browse',
  'sm.image_file_type_error': '[ES]Error: File type must be .png, .jpeg, .jpg',
  'sm.image_px_error':
    '[ES]Error: File must be at least 250px wide and 100px tall, and at most 1920px wide and 1080px tall',
  'sm.image_size_error': '[ES]Error: File must be under 5MB in size',
  'sm.easy_import_image_file_type_error': '[ES]Error: File type must be .png, .jpeg, .jpg, .webp',
  'sm.easy_import_image_size_error': '[ES]Error: File must be under 20MB in size',
  'sm.easy_import_no_menu_selected': '[ES]Error: No target menu selected for import',
  'sm.easy_import_no_image': '[ES]Error: No image uploaded',
  'sm.easy_import_upload': '[ES] Upload (Agents only)',
  'sm.easy_import_success': '[ES] Please check back in a minute to see the imported menu',
  'sm.menu_items.share_to_group': '[ES] Share to Group',
  'sm.menu_items.share_to_group.confirmation_modal.title':
    '[ES] Are you sure you want to share menu item "{{label}}" to the Restaurant Group?',
  'sm.menu_items.share_to_group.confirmation_modal.message':
    '[ES] Sharing this menu item moves it to the restaurant group level, so it can be shared with all venues in your group. \n\nWhen this happens, only the basic item details will be carried over. Some settings—like sales categories, modifier groups, or menu groups—won’t transfer since it is possible they do not exists at the group level. \n\nOnce shared, all venues in the group will inherit this item and any future updates made at the group level. The original item at the venue level will stay the same. \n\nIf you want it to follow group-level settings, use the Revert to Default option.',
  'sm.menu_items.no_menu_item_selected.error': '[ES] Error: No menu item selected',
  'sm.menu_items.revert_to_default': '[ES] Revert to Default',
  'sm.menu_items.revert_to_default.confirmation_modal.title':
    '[ES] Are you sure you want to revert this menu item "{{label}}" back to group-level settings?',
  'sm.menu_items.revert_to_default.confirmation_modal.message':
    '[ES] Choosing Revert to Default will remove any custom changes made at the venue level and update the item to match the group-level settings. For example:',
  'sm.menu_items.revert_to_default.current_venue_settings': '[ES] Current Venue Settings:',
  'sm.menu_items.revert_to_default.current_venue_settings.name': '[ES] Name: "Daily Special $10"',
  'sm.menu_items.revert_to_default.current_venue_settings.price': '[ES] Price: $10',
  'sm.menu_items.revert_to_default.restaurant_group_settings': '[ES] Restaurant Group Settings:',
  'sm.menu_items.revert_to_default.restaurant_group_settings.name': '[ES] Name: "Daily Special"',
  'sm.menu_items.revert_to_default.restaurant_group_settings.price': '[ES] Price: Open price',
  'sm.menu_items.revert_to_default.after_reverting_to_default': '[ES] After Reverting to Default:',
  'sm.menu_items.revert_to_default.after_reverting_to_default.name':
    '[ES] The item name changes to "Daily Special".',
  'sm.menu_items.revert_to_default.after_reverting_to_default.price':
    '[ES] The price follows the group setting (Open price).',
  'sm.menu_items.revert_to_default.confirmation_modal.footer':
    '[ES] This ensures the menu item stays consistent across all venues in the group.',
  'sm.menu_items.linked': '[ES] Linked',
  'sm.menu_items.linked.base_type_group': '[ES] Group',
  'sm.menu_items.linked.base_type_venue': '[ES] Venue',
  'sm.menu_items.modal_title.bulk_delete':
    '[ES] Are you sure you want to delete {{number}} menu item(s)?',
  'sm.mod_groups.modal_title.bulk_delete':
    '[ES] Are you sure you want to delete {{number}} modifier group(s)?',
  'sm.menu_groups.modal_title.bulk_delete':
    '[ES] Are you sure you want to delete {{number}} menu group(s)?',
  'sm.menu_groups.delete.success': "[ES] '{{label}}' has been deleted from your menu groups",
  'sm.menu_groups.menu_items.bulk_update':
    '[ES] Menu item changes will add to the existing setup for all selected menu groups.',
  'sm.menu_groups.color': '[ES] Color',
  'sm.menu_groups.color.bulk_update': '[ES] Color changes will apply to all selected items.',
  'sm.menu_groups.color.bulk_update.helper': '[ES] This color will appear on your POS.',
  'sm.icon.bulk_update': '[ES] Icon changes will apply to all selected items.',
  'sm.icon.bulk_update.helper': '[ES] This icon will appear on your POS.',
  'sm.menu_groups.color.updated': '[ES] Color has been updated!',
  'sm.icon.updated': '[ES] Icon has been updated!',
  'sm.menus.modal_title.bulk_delete': '[ES] Are you sure you want to delete {{number}} menu(s)?',
  'sm.menus.delete.success': "[ES] '{{label}}' has been deleted from your menus",
  'sm.bulk_delete.success': '[ES] {{number}} item(s) were deleted.',
  'sm.delete_modifier_group': '[ES] Delete Modifier Group',
  'sm.menu_items.updated': 'Menu items updated!',
  'sm.menu_items.out_of_stock.bulk_update':
    '[ES] Are you sure you want to mark the selected item(s) out of stock?',
  'sm.menu_items.back_in_stock.bulk_update':
    '[ES]Are you sure you want to mark the selected item(s) back in stock?',
  'sm.added': '[ES] Added',
  'sm.removed': '[ES] Removed',
  'sm.bulk_pricing.explanation': '[ES] Price changes will apply to all selected items.',
  'sm.bulk_pricing.increase': '[ES] Increase Price',
  'sm.bulk_pricing.decrease': '[ES] Decrease Price',
  'sm.bulk_pricing.increase_or_decrease': '[ES] Increase or decrease prices by:',
  'sm.bulk_pricing.updated': '[ES] Prices successfully updated!',
  'sm.menu_item_image': '[ES] Menu Item Image',
  'sm.delete_image': '[ES] Delete image',
  'sm.img_requirements':
    '[ES] Please upload images that are at least 250px wide, 100px tall and maximum 1920px wide and 1080px tall, in a PNG or JPG file format and under 5MB in size. This image will be used in Online Ordering and POS if enabled.',
  'sm.full_menu': '[ES] Full Menu',
  'sm.admin_register': '[ES] Admin: Register',
  'sm.current_order': "[ES] Current Order For Admin's Register",
  'sm.back_to_publishing': '[ES] Back to Publishing',
  'sm.open': '[ES] Open',
  'sm.available_for.updated': '[ES] "Available For" updated!',
  'sm.no_available_for_available': '[ES] No "Available For" available',
  'sm.menu_items.available_for.options': '[ES] "Available For" Options',
  'sm.menu_items.available_for.add': '[ES] Add "Available For"',
  'sm.menu_items.available_for.remove': '[ES] Remove "Available For"',
  'sm.menu_items.available_for.bulk_update':
    '[ES] "Available For" changes will add/remove where your menu item is offered for all selected items.',
  // promotional pricing
  'sm.promotional_pricing': '[ES] Promotional Pricing',
  'sm.promotional_pricing_explanation':
    '[ES] Promotional Pricing allows you to offer special deals and discounts on your menu items during a specific period of time. This can be achieved by using the discounts you\'ve set up or by offering a fixed price. Examples of Promotional Pricing include happy hour deals, holiday prices, or reduced pricing for specific menu items during non-peak times. To get started, click "Add Promotion" in the top right corner.',
  'sm.fixed_price_promotion_example': '[ES] Fixed Price Promotion Example',
  'sm.discount_promotion_example': '[ES] Discount Promotion Example',
  'sm.Promotion': '[ES] Promotion',
  'sm.promotion': '[ES] promotion',
  'sm.promotion_type': '[ES] PromotionType',
  'sm.promotion_type_discount': '[ES] Discount',
  'sm.promotion_type_fixed_price': '[ES] Fixed Price',
  'sm.add_promotion': '[ES] Add Promotion',
  'sm.edit_promotion': '[ES] Edit Promotion',
  'sm.search_promotion_placeholder': '[ES] Search promotions',
  'sm.promotions.modal_title.bulk_delete':
    '[ES] Are you sure you want to delete {{total}} promotion(s)?',
  'sm.promotions.modal_title.delete':
    "[ES] Are you sure you want to delete the promotion named '{{label}}'?",
  'sm.promotion.delete_promotion': '[ES] Delete Promotion',
  'sm.promotions.delete.success': "[ES] '{{label}}' has been deleted from your promotions",
  'sm.promotions.back': '[ES] Back to Promotions',
  'sm.promotion_details': '[ES] Promotion Details',
  'sm.promotion.create.name_label': '[ES] Promotion Name',
  'sm.promotion.create.name_helper':
    '[ES] Your promotion name can be displayed to your staff and customers.',
  'sm.promotion.discount_select_option': '[ES] Select Discount Option',
  'sm.promotion_price': '[ES] Promotion Price',
  'sm.schedule_description.promotion': '[ES] Choose when your promotion is offered.',
  'sm.promotion.discount_option': '[ES] Discount Option',
  'sm.promotion_attach_menu_group_and_item':
    '[ES] Select Menu Groups/Menu Items that this Promotion can be applied to.',
  'sm.promotion_attach_menu_group_and_item_button': '[ES] Search Menu Groups/Menu Items',
  'sm.promotion_title_menu_group': '[ES] Select Menu Groups/Items',
  'sm.promotion_title_menu_item': '[ES] Select Menu Items',
  'sm.promotion_attach_menu_group_and_item_subtitle':
    '[ES] The selected menu groups/items will be applied the discount.',
  'sm.promotion_attach_menu_group_and_item_banner_title': '[ES] Heads up!',
  'sm.promotion_subtitle_menu_group':
    '[ES] Promotion will automatically include all subsequent additions to Menu Groups.',
  'sm.promotion_subtitle_menu_item':
    '[ES] Promotion will be applied to specific Menu Items you select. When all Menu Items are selected, the promotion will apply to the Menu Group. Promotion will automatically include all subsequent additions to Menu Groups.',
  'sm.promotion_back_to_all': '[ES] Back to menu groups',
  'sm.promotion_view_menu_items': '[ES] View Menu Items',
  'sm.promotion_no_menu_groups': '[ES] There are no Menu Groups.',
  'sm.promotion_no_menu_items': '[ES] There are no Menu Items for this Menu Group.',
  'sm.promotion_price_error':
    '[ES] Promotion price must be a decimal with maximum two digits after comma',
  // empty screens
  'sm.empty_screen_receipt_venue': 'TouchBistro Cafe',
  'sm.empty_screen_receipt_street_address': '590 King Street West',
  'sm.empty_screen_receipt_city_address': 'Toronto, ON',
  'sm.empty_screen_receipt_country_address': 'Canada, L6M 4H7',
  'sm.empty_screen_receipt_venue_phone': '(905)-399-5432',
  'sm.empty_screen_receipt_date': 'February 15, 2020 at 3:53 PM',
  'sm.empty_screen_receipt_table': 'Table: Cash Register, 1 guest',
  'sm.empty_screen_receipt_party': 'Party Name: 1',
  'sm.empty_screen_receipt_tax': 'Tax #: R0012345654323',
  'sm.empty_screen_receipt_order': 'Order: 5870',
  'sm.empty_screen_receipt_admin': 'Admin: Emily',
  'sm.empty_screen_receipt_item_veggie_tacos': 'Veggie Tacos',
  'sm.empty_screen_receipt_item_veggie_tacos_modifier': '+ No Lettuce',
  'sm.empty_screen_receipt_friday_lunch_discount': 'Discount - Lunch Friday',
  'sm.empty_screen_receipt_taco_tuesday_promotion': '$11.29 - $6.29 - Taco Tuesday',
  'sm.empty_screen_receipt_happy_hour_promotion': 'Promotion - Happy Hour',
  'sm.empty_screen_receipt_item_orange_juice': 'Orange Juice',
  'sm.empty_screen_receipt_item_orange_juice_modifier': '+ No Pulp',
  'sm.empty_screen_receipt_item_orange_juice_mimosa': 'Orange Juice Mismosa',
  'sm.empty_screen_receipt_item_veggie_tacos_cost': '$11.29',
  'sm.empty_screen_receipt_item_veggie_tacos_discounted_cost': '$5.00',
  'sm.empty_screen_receipt_item_veggie_tacos_discount_amount': '-$2.82',
  'sm.empty_screen_receipt_item_orange_juice_mimosa_cost': '$13.55',
  'sm.empty_screen_receipt_item_orange_juice_discount_amount': '-$2.03',
  'sm.empty_screen_receipt_bill_amount_due': 'Amount Due',
  'sm.empty_screen_receipt_bill_amount_due_taxes': '$24.84',
  'sm.empty_screen_receipt_bill_discounted_amount_due': '$19.99',
  'sm.empty_screen_receipt_bill_promotions_amount_due': '$18.55',
  'sm.empty_screen_receipt_bill_hst': 'HST (13%):',
  'sm.empty_screen_receipt_bill_tip': 'Tip',
  'sm.empty_screen_receipt_bill_total': 'Total',
  'sm.empty_screen_receipt_bill_hst_value_taxes': '$3.23',
  'sm.empty_screen_receipt_bill_hst_value_discounts': '$2.60',
  'sm.empty_screen_receipt_bill_hst_value_promotions': '$2.41',
  'sm.empty_screen_receipt_bill_tip_value': '$4.50',
  'sm.empty_screen_receipt_bill_total_value_taxes': '$32.57',
  'sm.empty_screen_receipt_bill_total_value_discounts': '$27.09',
  'sm.empty_screen_receipt_bill_total_value_promotions': '$25.46',
  'sm.empty_screen_receipt_footer_thank_you': 'Thank You!',
  'sm.empty_screen_receipt_footer_come_again': 'Please Come Again',
  // errors
  'sm.label_max_length': 'La etiqueta tiene más de 500 caracteres',
  'sm.description_max_length': 'La descripción tiene más de 1000 caracteres',
  'sm.upc_max_length': '[ES] UPC Code is longer than 50 characters',
  'sm.item_label_max_length': 'La etiqueta tiene más de 1000 caracteres',
  'sm.error.upc_duplicate':
    '[ES] UPC is already in use by another menu item. Please input a unique product code.',
  'sm.error.upc_duplicate_multi_item_create':
    '[ES] Menu Items: {{menuItemLabels}} have duplicate UPC Codes',
  'sm.error.no_negative_value': "[ES] This field can't contain negative value",
  'sm.error.schedule.menu': '[ES] Please select the day(s) your menu is available.',
  'sm.error.schedule.menu_group': '[ES] Please select the day(s) your menu group is available.',
  'sm.error.duplicate_schedules': '[ES] Cannot create duplicate schedules.',
  'sm.error.open_and_returnable': '[ES] Open Priced Items cannot have returns enabled.',

  'sm.easy_assign_sales_categories': '[ES] Auto Assign Sales Categories',
  'sm.easy_assign_sales_categories_title': '[ES] Sales Categories',
  'sm.easy_assign_sales_categories_empty_state': '[ES] All menu items are categorized',
  'sm.easy_assign_sales_categories_something_went_wrong': '[ES] Something went wrong',

  // entity history
  'sm.pending_updates': '[ES] Pending Updates',
  'sm.pending_updates.subtitle':
    '[ES] These are your updates to the unpublished item since the last publishing.',
  'sm.entity_history.field_name': '[ES] {{key}}',
  'sm.entity_history.field_name_plu': '[ES] PLU Code',
  'sm.entity_history.field_name_color': '[ES] Background Color',
  'sm.entity_history.field_name_label': '[ES] Name',
  'sm.entity_history.field_name_prices': '[ES] Pricing',
  'sm.entity_history.field_name_taxes': '[ES] Taxes',
  'sm.entity_history.field_name_taxRules': '[ES] Tax Rules',
  'sm.entity_history.field_name_takeoutTypes': '[ES] Tax Exclusions',
  'sm.entity_history.field_name_menuGroups': '[ES] Menu Groups',
  'sm.entity_history.field_name_menuItems': '[ES] Menu Items',
  'sm.entity_history.field_name_menus': '[ES] Menus',
  'sm.entity_history.field_name_printerGroups': '[ES] Printer Groups',
  'sm.entity_history.field_name_modifierGroups': '[ES] Modifier Groups',
  'sm.entity_history.field_name_images': '[ES] Image',
  'sm.entity_history.field_name_shortName': '[ES] Short Name',
  'sm.entity_history.field_name_isGiftCard': '[ES] This item is a gift card',
  'sm.entity_history.field_name_isAlcohol': '[ES] This item contains Alcohol',
  'sm.entity_history.field_name_isReturnable': '[ES] This Item can be returned',
  'sm.entity_history.field_name_description': '[ES] Description',
  'sm.entity_history.field_name_isOutOfStock': '[ES] Item out of stock',
  'sm.entity_history.field_name_salesChannels': '[ES] Available For',
  'sm.entity_history.field_name_rank': '[ES] Rank',
  'sm.entity_history.field_name_schedule': '[ES] Schedule',
  'sm.entity_history.field_name_salesCategoryName': '[ES] Sales Category',
  'sm.entity_history.field_name_courseName': '[ES] Course',
  'sm.entity_history.field_name_requiresManagersApproval':
    '[ES] Requires managers approval to order',
  'sm.entity_history.field_name_icon': '[ES] Icon',
  'sm.entity_history.field_name_backgroundColor': '[ES] Background Color',
  'sm.entity_history.field_name_maxSelection': '[ES] Maximum Modifiers',
  'sm.entity_history.field_name_minSelection': '[ES] Minimum Modifiers',
  'sm.entity_history.field_name_modifiers': '[ES] Modifiers',
  'sm.entity_history.field_name_required': '[ES] Required',
  'sm.entity_history.field_name_cost': '[ES] Total cost of ingredients',
  'sm.entity_history.field_name_isTaxable': '[ES] Taxable',
  'sm.entity_history.field_name_isReturnInventory': '[ES] Returns Inventory',
  'sm.entity_history.field_name_amount': '[ES] Amount',
  'sm.entity_history.field_name_amountType': '[ES] Amount Type',
  'sm.entity_history.field_name_reducedTaxAmount': '[ES] Reduced Tax Amount',
  'sm.entity_history.field_name_reducedTaxRate': '[ES] Reduced Tax Rate',
  'sm.entity_history.field_name_type': '[ES] Type',
  'sm.entity_history.field_name_price': '[ES] Price',
  'sm.entity_history.field_name_discountLabel': '[ES] Discount Name',
  'sm.entity_history.field_name_isActive': '[ES] Is Active',
  'sm.entity_history.field_name_applyType': '[ES] Apply Type',
  'sm.entity_history.field_name_promoCodes': '[ES] Promo Codes',
  'sm.entity_history.field_name_availability': '[ES] Availability',
  'sm.entity_history.field_name_discountType': '[ES] Discount Type',
  'sm.entity_history.event_row': '[ES] {{event}} by {{actor}} at {{date}}',
  'sm.entity_history.tax_rate': '[ES] {{rate}}%',
  'sm.entity_history.tax_amount': '[ES] ${{amount}}',
  'sm.entity_history.requiresManagerOverride': '[ES] Requires Manager Approval',
  'sm.entity_history.chargeType': '[ES] Charge Type',
  'sm.entity_history.receivedBy': '[ES] Received By',
  'sm.unpublished_item': '[ES] Unpublished Item',
  'sm.saved_by': '[ES] Saved By',
  'sm.field': '[ES] Field',
  'sm.after': '[ES] After',
  'sm.before': '[ES] Before',
  'sm.select_all_banner_message_menu_groups_select_page':
    '[ES] You have selected {{pageCount}} items. <1>Select all {{count}} items in Menu Groups</1>',
  'sm.select_all_banner_message_menu_groups_select_all':
    '[ES] All {{count}} records in Menu Groups are selected. <1>Clear Selection</1>',
  'sm.select_all_banner_message_menu_items_select_page':
    '[ES] You have selected {{pageCount}} items. <1>Select all {{count}} items in Menu Items</1>',
  'sm.select_all_banner_message_menu_items_select_all':
    '[ES] All {{count}} records in Menu Items are selected. <1>Clear Selection</1>',
  'sm.select_all_banner_message_modifier_groups_select_page':
    '[ES] You have selected {{pageCount}} items. <1>Select all {{count}} items in Modifier Groups</1>',
  'sm.select_all_banner_message_modifier_groups_select_all':
    '[ES] All {{count}} records in Modifier Groups are selected. <1>Clear Selection</1>',
  'sm.select_all_banner_message_promotions_select_page':
    '[ES] You have selected {{pageCount}} Promotions. <1>Select all {{total}} items in Promotions</1>',
  'sm.select_all_banner_message_promotions_select_all':
    '[ES] All {{total}} records in Promotions are selected. <1>Clear Selection</1>',
  'sm.revision_history_title': '[ES] {{label}} Revision History',
  'sm.no_revision_history': '[ES] Revision history not found for {{label}}',
  'sm.delete_history_title': 'Delete History',
  'sm.delete_history_title_menu': '[ES] Menu Delete History',
  'sm.delete_history_title_menu_item': '[ES] Menu Item Delete History',
  'sm.delete_history_title_menu_group': '[ES] Menu Group Delete History',
  'sm.delete_history_title_sales_category': '[ES] Sales Category Delete History',
  'sm.delete_history_title_void': '[ES] Void Reason Delete History',
  'sm.delete_history_title_modifier_group': '[ES] Modifier Group Delete History',
  'sm.delete_history_title_printer_group': '[ES] Prep Station Delete History',
  'sm.delete_history_title_tax': '[ES] Tax Delete History',
  'sm.delete_history_title_discount': '[ES] Discount Delete History',
  'sm.delete_history_title_promotion': '[ES] Promotion Delete History',
  'sm.delete_history_subtitle': '[ES] Below is a log of the last 50 deleted {{entity}}.',
  'sm.deleted_entities': '[ES] deleted entities',
  'sm.deleted_entities_menu': '[ES] menus',
  'sm.deleted_entities_menu_item': '[ES] menu items',
  'sm.deleted_entities_menu_group': '[ES] menu groups',
  'sm.deleted_entity_title_menu': '[ES] Deleted Menu',
  'sm.deleted_entity_title_menu_item': '[ES] Deleted Item',
  'sm.deleted_entity_title_menu_group': '[ES] Deleted Menu Group',
  'sm.deleted_entity_title': '[ES] Deleted Entity',
  'sm.deleted_entities_sales_category': '[ES] sales categories',
  'sm.deleted_entities_void': '[ES] void reasons',
  'sm.deleted_entities_modifier_group': '[ES] modifier groups',
  'sm.deleted_entities_printer_group': '[ES] prep stations',
  'sm.deleted_entities_tax': '[ES] taxes',
  'sm.deleted_entities_discount': '[ES] discounts',
  'sm.deleted_entities_promotion': '[ES] promotions',
  'sm.deleted_entity_title_sales_category': '[ES] Deleted Sales Category',
  'sm.deleted_entity_title_void': '[ES] Deleted Void Reason',
  'sm.deleted_entity_title_modifier_group': '[ES] Deleted Modifier Group',
  'sm.deleted_entity_title_printer_group': '[ES] Deleted Prep Station',
  'sm.deleted_entity_title_tax': '[ES] Deleted Tax',
  'sm.deleted_entity_title_discount': '[ES] Deleted Discount',
  'sm.deleted_entity_title_promotion': '[ES] Deleted Promotions',
  'sm.no_delete_history': '[ES] Entity has no delete history',
  'sm.no_delete_history_menu': '[ES] No delete history found for menus',
  'sm.no_delete_history_menu_group': '[ES] No delete history found for menu groups',
  'sm.no_delete_history_menu_item': '[ES] No delete history found for menu items',
  'sm.no_delete_history_sales_category': '[ES] No delete history found for sales categories',
  'sm.no_delete_history_void': '[ES] No delete history found for void reasons',
  'sm.no_delete_history_modifier_group': '[ES] No delete history found for modifier groups',
  'sm.no_delete_history_printer_group': '[ES] No delete history found for prep stations',
  'sm.no_delete_history_tax': '[ES] No delete history found for taxes',
  'sm.no_delete_history_discount': '[ES] No delete history found for discounts',
  'sm.no_delete_history_promotion': '[ES] No delete history found for promotions',
  'sm.delete_history': '[ES] Delete History',
  deleted_by: '[ES] Deleted By',

  /* VOIDS */
  'sm.voids_title': '[ES] Made a mistake? Void it!',
  'sm.voids_explanation':
    "[ES] Voids communicate to kitchen staff any menu items that need to be removed from orders. For example: a server accidentally charges veggie tacos to the wrong table. If the order has been sent to the kitchen for preparation, the server can void the item and apply the void reason. After the void ticket has been sent to the kitchen, the server can then add the correct item to the customer's order.",
  'sm.voids_example': '[ES] Void Example',

  /* DISCOUNTS START */
  'sm.add_discount': '[ES] Add Discount',
  'sm.edit_discount': '[ES] Edit Discount',
  'sm.back_to_discounts': '[ES] Back To Discounts',
  'sm.discount_details': '[ES] Discount Details',
  'sm.discount': '[ES] discount',
  'sm.Discount': '[ES] Discount',
  'sm.discounts': '[ES] Discounts',
  'sm.delete_discount': '[ES] Delete Discount',
  'sm.delete_discount.success': "[ES] '{{label}}' has been deleted from discounts",
  'sm.discounts_explanation': `[ES] By taking a strategic approach to discounts, your restaurant can reap the benefits and achieve your business goals - whether you want to attract new customers, drive repeat business, or increase profitability. Common discounts include: Staff, Happy Hour, or Birthday discount. You can set which Staff Types can apply Discounts on your POS.`,
  'sm.discounts_example': '[ES] Discounts Example',
  'sm.search_discounts': '[ES] Search discounts',
  'sm.create_discount': '[ES] Create Discount',
  'sm.discount_type': '[ES] Discount Type',
  'sm.discounts_applied_before_tax': '[ES] All discounts are applied before tax.',
  'sm.select_menu_groups_for_discount':
    '[ES] Select menu groups that this discount can be applied to.',
  'sm.discount_type.amount_off_items': '[ES] % or $ off Items',
  'sm.exclude_discount_attach_menu_group_and_item':
    '[ES] Select Menu Groups/Menu Items that should be excluded from the Order Level Discount',
  'sm.exclude_discount_attach_menu_group_and_item_subtitle':
    '[ES] Order level discounts apply to everything in an order, but you have the option to exclude certain items, such as alcohol, from receiving the discount. Simply choose the items or groups you want to exclude.',
  'sm.discount_attach_menu_group_and_item':
    '[ES] Select Menu Groups/Menu Items that this Discount can be applied to.',
  'sm.discount_attach_menu_group_and_item_button': '[ES] Search Menu Groups/Menu Items',
  'sm.discount_title_menu_group': '[ES] Select Menu Groups/Items',
  'sm.discount_title_menu_item': '[ES] Select Menu Items',
  'sm.discount_attach_menu_group_and_item_subtitle':
    '[ES] The selected menu groups/items will be applied the discount.',
  'sm.discount_attach_menu_group_and_item_banner_title': 'Heads up!',
  'sm.discount_subtitle_menu_group':
    'Discounts will automatically include all subsequent additions to Menu Groups.',
  'sm.discount_subtitle_menu_item':
    '[ES] Discount will be applied to specific Menu Items you select. When all Menu Items are selected, the discount will apply to the Menu Group. Discount will automatically include all subsequent additions to Menu Groups.',
  'sm.discount_back_to_all': 'Back to menu groups',
  'sm.discount_view_menu_items': '[ES] View Menu Items',
  'sm.discount_no_menu_groups': '[ES] There are no Menu Groups.',
  'sm.discount_no_menu_items': '[ES] There are no Menu Items for this Menu Group.',
  /* DISCOUNTS END */
  'sm.create_success': '[ES] Your {{item}} has been successfully added',
  'sm.update_success': '[ES] Your {{item}} has been successfully updated',
  'sm.discount_name': '[ES] Discount Name',
  'sm.discount_name.helper':
    '[ES] Your discount name can be displayed to your staff and customers.',
  'sm.amount_off': '[ES] Amount off',
  'sm.invalid_amount':
    '[ES] Discount dollar amount must be between 0.01 and 10000 with no $ symbol.',
  'sm.invalid_percent':
    '[ES] Discount percentage must be between 0.001 and 100, up to three decimal places and no % symbol.',
  'sm.discounts_replacement_discount': '[ES] Replacement Discount for Promotion(s)',
  'sm.delete_discount_warning':
    '[ES] WARNING: Deleting this last discount will also delete the promotion(s) associated with it',
  'sm.order': '[ES] Order',
  'sm.item': '[ES] Item',
  'sm.ad_order': '[ES] Bill',
  'sm.ad_item': '[ES] Item',
  'sm.discount_type_item': '% or $ off Items',
  'sm.discount_type_order': '% or $ off Order',
  'sm.discount_type_helper': 'Once a discount type has been saved, it cannot be edited.',

  /* ADVANCED DISCOUNT START */
  'sm.empty_discount_list': '[ES] You currently don’t have any Discounts',
  'sm.create_new_discount': '[ES] Create',
  'sm.discount_amount': '[ES] Amount',
  'sm.discount_min_spend': '[ES] Min Spend',
  'sm.discount_max_spend': '[ES] Max Spend',
  'sm.discount_min_spend_placeholder': '[ES] Min amount ($)',
  'sm.discount_max_spend_placeholder': '[ES] Max amount ($)',
  'sm.discount_min_max_helper':
    '[ES] The bill amount must be between the min and max amount for the discount to apply',
  'sm.discount_available_for': '[ES] Available For',
  'sm.discount_method': '[ES] Method',
  'sm.status': '[ES] Status',
  'sm.combo': '[ES] Combo - {{comboPricingMethod}}',
  'sm.fixed_price': '[ES] Fixed Price',
  'sm.fixed_discount': '[ES] Fixed Discount',
  'sm.bogo': '[ES] Buy X Get Y',
  'sm.discount_apply_type_manual': '[ES] Manual',
  'sm.discount_apply_type_automatic': '[ES] Automatic',
  'sm.discount_apply_type_promo': '[ES] Promo Code',
  'sm.enum_ACTIVE': '[ES] Active',
  'sm.enum_INACTIVE': '[ES] Inactive',
  'sm.promo_code_form_code': '[ES] Promo Code',
  'sm.promo_code_form_description': '[ES] Description',
  'sm.promo_code_form_start_date': '[ES] Start Date',
  'sm.promo_code_form_end_date': '[ES] End Date',
  'sm.promo_code_form_status_active': '[ES] Active',
  'sm.promo_code_form_status_expired': '[ES] Expired',
  'sm.promo_code_form_status_scheduled': '[ES] Scheduled',
  'sm.discount_form_subHeader_availability': '[ES] Availability',
  'sm.discount_form_availability_subHeader_text':
    '[ES] Define the conditions for when the discount will apply',
  'sm.discount_form_label_promoCode': '[ES] Promo Code',
  'sm.discount_form_promoCode_label_text':
    '[ES] Create promo codes that customers can use to claim the discount at the venue or during the checkout for online orders',
  'sm.discount_form_action_add_promoCode': '[ES] Add Promo Code',
  'sm.discount_form_label_availableFor': '[ES] Available For',
  'sm.discount_form_availableFor_label_text':
    '[ES] Third party applications are excluded from this discount\n(Disclaimer: This discount will not be available for third party applications)',
  'sm.discount_form_label_applyDiscount': '[ES] Apply Discount',
  'sm.discount_form_applyDiscount_options': {
    AUTOMATIC: '[ES] Automatically',
    MANUAL: '[ES] Manually',
    PROMO: '[ES] Promo Codes',
  },
  'sm.discount_create_success': '[ES] Discount Created',
  'sm.discount_update_success': '[ES] Discount Updated',
  'sm.discount_detail_description': '[ES] Discounts will only be applied before taxes',
  'sm.discount_detail_type_item_1_label': '[ES] Item Discount',
  'sm.discount_detail_type_item_1_description': '[ES] Fixed $ or % off an item',
  'sm.discount_detail_type_item_2_label': '[ES] Bill Discount',
  'sm.discount_detail_type_item_2_description': '[ES] Fixed $ or % off the bill',
  'sm.discount_detail_type_item_3_label': '[ES] Combo Discount',
  'sm.discount_detail_type_item_3_description': '[ES] Configure a combo to provide a discount',
  'sm.discount_detail_type_item_4_label': '[ES] Buy X Get Y',
  'sm.discount_detail_type_item_4_description':
    '[ES] Purchase certain items and receive a discount',
  'sm.discount_amount_advanced': '[ES] Discount Amount',
  'sm.discount_amount_helpertext': '[ES] The amount that will be discounted from the bill',
  'sm.invalid_discount_name_required': '[ES] Discount name is required',
  'sm.invalid_discount_name_length': '[ES] Discount name cannot exceed 250 characters',
  'sm.invalid_discount_type': '[ES] Choose a discount type',
  'sm.invalid_discount_amount_required': '[ES] Discount amount is required',
  'sm.invalid_discount_amount_type': '[ES] Discount amount must be a number',
  'sm.invalid_sales_channel': '[ES] At least one sales channel required',
  'sm.invalid_promo_codes_format': '[ES] Promo code should not contain Spaces or Symbol',
  'sm.invalid_promo_codes_length': '[ES] At least one promo code must be added',
  'sm.stock': '[ES] Stock',
  'sm.stock_availability': '[ES] Stock Availability',
  'sm.stock_count': '[ES] Stock Count',
  'sm.low_stock_indicator': '[ES] Low Stock Indicator',
  'sm.add_stock': '[ES] Add Stock',
  'sm.manage_stock': '[ES] Manage Stock',
  'sm.stock_availability.no_items_found':
    "[ES] You currently don't have any Menu Items configured to use Stock Availability",
  'sm.stock_availability.no_items_found_alt':
    '[ES] TouchBistro no Menu Items configured to use Stock Availability image',
  'sm.menu.stock_availability.add_modal.title': '[ES] {{label}} Stock',
  'sm.menu.stock_availability.add.success': '[ES] {{label}} stock has been successfully added.',
  'sm.menu.stock_availability.starting_count.label': '[ES] Enter your starting stock',
  'sm.menu.stock_availability.starting_count.required': '[ES] Please enter a stock amount',
  'sm.menu.stock_availability.set_count.max_exceeded':
    '[ES] The amount you entered cannot exceed {{maxCount}}',
  'sm.menu.stock_availability.low_stock_toggle.label': '[ES] Low Stock Indicator',
  'sm.menu.stock_availability.low_stock.required': '[ES] Please enter a low stock amount',
  'sm.menu.stock_availability.low_stock.below_min':
    '[ES] The amount you entered cannot be below {{minLowStockCount}}',
  'sm.menu.stock_availability.update_modal.radio_title': '[ES] Add or remove from your Stock Count',
  'sm.menu.stock_availability.update_modal.radio_add': '[ES] Add to stock',
  'sm.menu.stock_availability.update_modal.radio_remove': '[ES] Remove from stock',
  'sm.menu.stock_availability.update_modal.stock_count_amount': '[ES] Amount',
  'sm.menu.stock_availability.update_modal.stock_count_existing': '[ES] Stock: {{stockCount}}',
  'sm.menu.stock_availability.update_modal.stock_count_equation':
    '[ES] Stock: {{stockCount}} {{sign}} {{amount}} = {{sum}}',
  'sm.menu.stock_availability.update_modal.stock_count.required':
    '[ES] Please enter a stock amount',
  'sm.menu.stock_availability.update_modal.stock_count.sum_exceeds_max':
    '[ES] The new stock total cannot exceed {{maxCount}}',
  'sm.menu.stock_availability.update_modal.stock_count.sum_below_zero':
    '[ES] The amount to remove cannot be more than your current stock',
  'sm.menu.stock_availability.update_modal.disable_stock_count': '[ES] Disable Stock Count',
  'sm.menu.stock_availability.disable_stock_count_modal.title':
    '[ES] Are you sure you want to disable your stock count?',
  'sm.menu.stock_availability.disable_stock_count_modal.message':
    '[ES] Any Stock Count will be removed and you will no longer receive Low Stock Indicators. If you enable stock count again, you will have to input a new starting stock count.',
  'sm.menu.stock_availability.disable_stock_count_modal.confirm': '[ES] Disable Stock',
  'sm.menu.stock_availability.update.success':
    '[ES] {{label}} stock has been successfully updated.',
  'sm.menu.stock_availability.update.restock': '[ES] {{label}} is available again.',
  'sm.menu.stock_availability.update.no_item_selected': '[ES] No item has been selected',
  'sm.menu.stock_availability.update.item_not_in_list':
    '[ES] Item to be updated is not in the item list',
  'sm.menu.stock_availability.invalid_initial_stock_count_error':
    '[ES] The amount you entered cannot be below 0 or exceed {{maxCount}}',
  'sm.menu.stock_availability.request_error': '[ES] Failed to update Stock Availability',
  'sm.menu.stock_availability.oos_confirmation.title':
    '[ES] Marking {{label}} out of stock will disable the stock count',
  'sm.menu.stock_availability.oos_confirmation.text':
    '[ES] Any Stock Count will be removed and you will no longer receive Low Stock Indicators. If you enable stock count again, you will have to input a new starting stock count.',
  'sm.menu.stock_availability.oos_confirmation.mark_out_of_stock': '[ES] Mark Out of Stock',
  'sm.menu.stock_availability.oos_choice.title':
    '[ES] Making {{label}} available immediately will disable the stock count',
  'sm.menu.stock_availability.oos_choice.text':
    '[ES] {{label}} has 0 Stock Count. Making it available will disable stock count so it can be sold immediately. To keep your stock count, just add to the Stock Count.',
  'sm.menu.stock_availability.oos_choice.make_available_now': '[ES] Make Available Now',
  'sm.menu.stock_status_all_items': '[ES] All Items',
  'sm.menu.stock_status_in_stock': '[ES] Items In Stock',
  'sm.menu.stock_status_out_of_stock': '[ES] Items Out Of Stock',
  'sm.menu.stock_status_low_stock': '[ES] Items With Low Stock',
  'sm.delete_advanced_discount_message':
    '[ES] Deleting this discount will not affect your past reports',
  'sm.discount_applicaple_group_section_title': '[ES] Applicable Groups/Items',
  'sm.discount_exclude_group_section_title': '[ES] Exclude Groups/Items',
  'sm.discount_configure_get_buy_section_title': '[ES] Configure Buy & Get Items',
  'sm.discount_type_item_section_description':
    '[ES] Select the menu groups/items that the discount will apply to',
  'sm.discount_type_order_section_description': '[ES] Exclude menu groups/items from the discount',
  'sm.discount_type_bogo_section_description':
    '[ES] Define items that need to be purchased and items that will receive the discount',
  'sm.discount_promo_code_warning_title':
    '[ES] Applying the discount automatically will remove all Promo Codes created',
  'sm.discount_promo_code_warning_subtitle': '[ES] Are you sure you want to switch?',
  'sm.discount_promo_code_apply_automatically_btn': '[ES] Apply Automatically',
  'sm.discount_promo_code_apply_manually_btn': '[ES] Apply Manually',
  'sm.discount_min_max_spend_title': '[ES] Min/Max Spend',
  'sm.discount_min_max_spend_caption':
    '[ES] Set a min/max spend requirement before the discount is applied, and choose whether to exclude specific menu groups/items from the total spend',
  'sm.discount_min_max_spend_label': '[ES] Require a min/max spend for this discount?',
  'sm.discount_min_max_spend_radio_label_0': '[ES] Set a min and/or max spend requirement',
  'sm.discount_min_max_spend_radio_label_1': '[ES] No spend requirement',
  'sm.discount_exclude_all_alcohol': '[ES] Exclude all Alcohol',
  'sm.validation_min_or_max_required':
    '[ES] At least one field (Min or Max spend) must be provided',
  'sm.validation_sales_channel': '[ES] At least one SalesChannel must be selected',

  // Group and Item Selector Shared component
  group_item_selector_view_all_button: '[ES] View All',
  group_item_selector_dialog_view_all: '[ES] Click x to remove a group/item',
  group_item_selector_menu_items_count_msg: '[ES] {{count}} Menu Items',
  group_item_selector_menu_items_tab: '[ES] Menu Items',
  group_item_selector_menu_items_count_tab: '[ES] Menu Items ({{count}})',
  group_item_selector_menu_group_tab: '[ES] Menu Groups',
  group_item_selector_menu_group_count_tab: '[ES] Menu Group ({{count}})',
  'group_item_selector_select-menu-items-groups-btn': '[ES] Select Groups/Items',
  group_item_selector_no_group_selection: '[ES] No Menu Group Selected',
  group_item_selector_no_item_selection: '[ES] No Menu Item Selected',
  group_item_selector_exculde_all_alcohol: '[ES] Exclude all Alcohol',
  group_item_selector_menu_group_removed: '[ES][REMOVED GROUP]',
  group_item_modal_selector_descr_include:
    '[ES] Select menu groups/items that the discount will apply to',
  group_item_modal_selector_descr_exclude:
    '[ES] Select groups/items the discount can’t be applied to',
}

export default simplifiedMenuES
