/**
 * PermissionMap represents the permissions a user has for a particular venue.
 * It is a map of resources to a list of operations for said resource.
 */
export type PermissionMap = {
  [resource: string]: string[] | undefined
}

export enum Resource {
  MARKETPLACE = 'marketplace',
  BASE = 'base',
  DEVICE = 'device',
  DISCOUNT = 'discount',
  BULK_MENU = 'bulkMenu',
  MENU = 'menu',
  MENU_GROUP = 'menuGroup',
  MENU_ITEM = 'menuItem',
  MODIFIER_GROUP = 'modifierGroup',
  PAYMENTS = 'payments',
  PRINTER = 'printer',
  PROMOTION = 'promotion',
  REPORT = 'report',
  SALES_CATEGORY = 'salesCategory',
  STOCK_AVAILABILITY = 'stockAvailability',
  STAFF = 'staff',
  TAX = 'tax',
  VENUE = 'venue',
  VENUE_INFO = 'venueInfo',
  VENUE_INFO_DAY_PARTS = 'venueInfoDayParts',
  VENUE_INFO_OPERATING_HOURS = 'venueInfoOperatingHours',
  VENUE_INFO_SERVICE_TIME = 'venueInfoServiceTime',
  VOID_REASON = 'voidReason',
  FLOORPLAN = 'floorplan',
  GUEST_ENGAGEMENT = 'guestEngagement',
  OPERATIONAL_MENU = 'operationalMenu',
  RESERVATION = 'reservationSetting',
  ROLES = 'roles',
  OVERTIME_RULES = 'overtimeRules',
  PAYMENT_SETTINGS = 'paymentSettings',
  PAYMENT_TERMINALS = 'paymentTerminals',
  PAYMENT_CREDENTIALS = 'paymentCredentials',
  SERVICE_CHARGE = 'serviceCharge',
  SHIFTS = 'shifts',
}

export enum Operation {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  PUBLISH = 'publish',
  MANAGE = 'manage',
}

export enum Policies {
  MARKETPLACE_MANAGE = 'marketplace:manage',
  BASE_ADMIN = 'base:admin',
  VENUE_READ = 'venue:read',
  VENUE_UPDATE = 'venue:update',
  STAFF_READ = 'staff:read',
  STAFF_UPDATE = 'staff:update',
  STAFF_CREATE = 'staff:create',
  STAFF_DELETE = 'staff:delete',
  REPORT_READ = 'report:read',
  REPORT_CASH_DEPOSIT_FULL = 'report:manageDepositsFull',
  REPORT_CASH_DEPOSIT_BLIND = 'report:manageDepositsBlind',
  EOD_REPORT_READ = 'eodreport:read',
  DEVICE_READ = 'device:read',
  DEVICE_UPDATE = 'device:update',
  TAX_READ = 'tax:read',
  TAX_CREATE = 'tax:create',
  TAX_UPDATE = 'tax:update',
  TAX_DELETE = 'tax:delete',
  VOID_REASON_READ = 'voidReason:read',
  VOID_REASON_CREATE = 'voidReason:create',
  VOID_REASON_UPDATE = 'voidReason:update',
  VOID_REASON_DELETE = 'voidReason:delete',
  MENU_READ = 'menu:read',
  MENU_CREATE = 'menu:create',
  MENU_UPDATE = 'menu:update',
  MENU_DELETE = 'menu:delete',
  MENU_ITEM_READ = 'menuItem:read',
  MENU_ITEM_CREATE = 'menuItem:create',
  MENU_ITEM_UPDATE = 'menuItem:update',
  MENU_ITEM_DELETE = 'menuItem:delete',
  DELIVERY_BOUNDARY_CREATE = 'deliveryBoundary:create',
  DELIVERY_BOUNDARY_READ = 'deliveryBoundary:read',
  DELIVERY_BOUNDARY_UPDATE = 'deliveryBoundary:update',
  DELIVERY_BOUNDARY_DELETE = 'deliveryBoundary:delete',
  AUTO_PROCESS_ORDER_READ = 'autoProcessOrder:read',
  AUTO_PROCESS_ORDER_UPDATE = 'autoProcessOrder:update',
  AUTO_PROCESS_ORDER_DELETE = 'autoProcessOrder:delete',
  DISCOUNT_READ = 'discount:read',
  DISCOUNT_CREATE = 'discount:create',
  DISCOUNT_UPDATE = 'discount:update',
  DISCOUNT_DELETE = 'discount:delete',
  BULK_MENU_CREATE = 'bulkMenu:create',
  BULK_MENU_DELETE = 'bulkMenu:delete',
  MENU_GROUP_READ = 'menuGroup:read',
  MENU_GROUP_CREATE = 'menuGroup:create',
  MENU_GROUP_UPDATE = 'menuGroup:update',
  MENU_GROUP_DELETE = 'menuGroup:delete',
  MODIFIER_GROUP_READ = 'modifierGroup:read',
  MODIFIER_GROUP_CREATE = 'modifierGroup:create',
  MODIFIER_GROUP_UPDATE = 'modifierGroup:update',
  MODIFIER_GROUP_DELETE = 'modifierGroup:delete',
  PRINTER_READ = 'printer:read',
  PRINTER_CREATE = 'printer:create',
  PRINTER_UPDATE = 'printer:update',
  PRINTER_DELETE = 'printer:delete',
  SALES_CATEGORY_READ = 'salesCategory:read',
  SALES_CATEGORY_DELETE = 'salesCategory:delete',
  SALES_CATEGORY_CREATE = 'salesCategory:create',
  SALES_CATEGORY_UPDATE = 'salesCategory:update',
  SERVICE_CHARGE_CREATE = 'serviceCharge:create',
  SERVICE_CHARGE_READ = 'serviceCharge:read',
  SERVICE_CHARGE_UPDATE = 'serviceCharge:update',
  SERVICE_CHARGE_DELETE = 'serviceCharge:delete',
  MODIFIER_READ = 'modifier:read',
  MODIFIER_CREATE = 'modifier:create',
  MODIFIER_UPDATE = 'modifier:update',
  MODIFIER_DELETE = 'modifier:delete',
  PROMOTION_READ = 'promotion:read',
  PROMOTION_CREATE = 'promotion:create',
  PROMOTION_UPDATE = 'promotion:update',
  PROMOTION_DELETE = 'promotion:delete',
  FLOORPLAN_CREATE = 'floorplan:create',
  FLOORPLAN_READ = 'floorplan:read',
  FLOORPLAN_UPDATE = 'floorplan:update',
  FLOORPLAN_DELETE = 'floorplan:delete',
  OPERATIONAL_MENU_PUBLISH = 'operationalMenu:publish',
  OPERATIONAL_MENU_READ_HISTORY = 'operationalMenu:readHistory',
  GUEST_ENGAGEMENT_CREATE = 'guestEngagement:create',
  GUEST_ENGAGEMENT_READ = 'guestEngagement:read',
  GUEST_ENGAGEMENT_UPDATE = 'guestEngagement:update',
  GUEST_ENGAGEMENT_DELETE = 'guestEngagement:delete',
  RESERVATION_SETTING_CREATE = 'reservationSetting:create',
  RESERVATION_SETTING_READ = 'reservationSetting:read',
  RESERVATION_SETTING_UPDATE = 'reservationSetting:update',
  RESERVATION_SETTING_DELETE = 'reservationSetting:delete',
  RESERVATION_CREATE = 'reservation:create',
  RESERVATION_READ = 'reservation:read',
  RESERVATION_UPDATE = 'reservation:update',
  RESERVATION_DELETE = 'reservation:delete',
  BRANDING_CREATE = 'branding:create',
  BRANDING_READ = 'branding:read',
  BRANDING_UPDATE = 'branding:update',
  BRANDING_DELETE = 'branding:delete',
  PAYMENTS_CREATE = 'payments:create',
  PAYMENTS_DELETE = 'payments:delete',
  PAYMENTS_READ = 'payments:read',
  PAYMENTS_UPDATE = 'payments:update',
  POS_ACCESS_CASH_REGISTER = 'pos:accessCashRegister',
  POS_ACCESS_CUSTOMER_ACCOUNTS = 'pos:accessCustomerAccounts',
  POS_ACCESS_TABLE_IF_SAME_ROLE = 'pos:accessTableIfSameRole',
  POS_ACCESS_TAKEOUT = 'pos:accessTakeout',
  POS_ADMIN = 'pos:admin',
  POS_BACK_OF_HOUSE = 'pos:backOfHouse',
  POS_CLOCK_IN_ONLY = 'pos:clockInOnly',
  POS_ENABLE_FAST_BAR = 'pos:enableFastBar',
  POS_ENABLE_PERSONAL_REGISTER = 'pos:enablePersonalRegister',
  POS_END_OF_SHIFT_REPORT = 'report:endOfShift',
  POS_LAUNCH_TB = 'pos:launchTouchBistro',
  POS_MANAGE = 'pos:manage',
  POS_MANAGE_BILL = 'order:refund',
  POS_MANAGE_DISCOUNTS = 'order:discount',
  POS_MANAGE_GRATUITY = 'order:gratuity',
  POS_MANAGE_ORDERS = 'pos:manageOrders',
  POS_MANAGE_PAYMENT_GATEWAYS = 'pos:managePaymentGateways',
  POS_MANAGE_VOIDS = 'order:void',
  POS_OPEN_CASH_DRAWER = 'cashdrawer:open',
  POS_PERFORM_END_OF_DAY = 'pos:performEndOfDay',
  POS_PERFORM_PAY_IN_PAY_OUT = 'cashdrawer:payInOut',
  POS_PERFORM_RETURNS = 'order:refund',
  POS_READ = 'pos:read',
  POS_REQUIRE_CASH_TIP = 'pos:requiresCashTip',
  POS_RESTART = 'pos:restart',
  ROLES_CREATE = 'roles:create',
  ROLES_UPDATE = 'roles:update',
  ROLES_DELETE = 'roles:delete',
  MENU_IMAGE_CREATE = 'image:create',
  MENU_IMAGE_READ = 'image:read',
  MENU_IMAGE_DELETE = 'image:delete',
  SCHEDULED_PUBLICATION_READ = 'scheduledPublication:read',
  SCHEDULED_PUBLICATION_UPDATE = 'scheduledPublication:update',
  SCHEDULED_PUBLICATION_DELETE = 'scheduledPublication:delete',
  ORDER_TAKE = 'order:take',
  VENUE_INFO_READ = 'venueInfo:read',
  VENUE_INFO_UPDATE = 'venueInfo:update',
  VENUE_INFO_DAY_PARTS_READ = 'venueInfoDayParts:read',
  VENUE_INFO_DAY_PARTS_UPDATE = 'venueInfoDayParts:update',
  VENUE_INFO_OPERATING_HOURS_READ = 'venueInfoOperatingHours:read',
  VENUE_INFO_OPERATING_HOURS_UPDATE = 'venueInfoOperatingHours:update',
  VENUE_INFO_SERVICE_TIME_READ = 'venueInfoServiceTime:read',
  VENUE_INFO_SERVICE_TIME_UPDATE = 'venueInfoServiceTime:update',
  MANAGE_SHIFTS = 'shifts:manage',
  MENU_ENTRY_HISTORY_READ = 'menuEntityHistory:read',
  OVERTIME_RULES_MANAGE = 'overtimeRules:manage',
  PARTNERS_SHIFT_OVERRIDE = 'partners:overrideShift',
  MANAGE_PAYMENT_SETTINGS = 'paymentSettings:manage',
  MANAGE_PAYMENT_TERMINALS = 'paymentTerminals:manage',
  MANAGE_PAYMENT_CREDENTIALS = 'paymentCredentials:manage',
  MANAGE_AUTHORIZE_PERFORM_SERVICE_CHARGES = 'pos:authorizeAndPerformServiceChargesWithoutApproval',
  MANAGE_AUTHORIZE_PERFORM_RESTRICTED_SERVICE_CHARGES = 'pos:authorizeAndPerformRestrictedServiceCharges',
  STOCK_AVAILABILITY_READ = 'stockAvailability:read',
  STOCK_AVAILABILITY_CREATE = 'stockAvailability:create',
  STOCK_AVAILABILITY_UPDATE = 'stockAvailability:update',
}

export enum AgentPolicies {
  SEARCH_READ = 'search:read',
  SUPPORT_PATCH_CREATE = 'supportPatch:create',
  TEST_VENUE_CREATE = 'testVenue:create',
  BULK_TEST_VENUE_CREATE = 'bulkTestVenue:create',
  TEST_VENUE_UPDATE = 'testVenue:update',
  TEST_GROUP_UPDATE = 'testGroup:update',
  POLICIES_READ = 'policies:read',
  ROLES_READ = 'roles:read',
  BASE_ROLES_READ = 'baseRoles:read',
  GROUP_DEMO_READ = 'groupDemo:read',
  RMM3_UPGRADE_CREATE = 'rmm3Upgrade:create',
  VENUE_DIAGNOSTIC_READ = 'venueDiagnostic:read',
  VENUE_STAFF_MEMBERS_READ = 'venueStaffMembers:read',
  VENUE_SUPPORT_PATCH_CREATE = 'venueSupportPatch:create',
  VENUE_LICENCE_CREATE = 'venueLicence:create',
  VENUE_RMM2_SYNC_UPDATE = 'venueRMM2Sync:update',
  VENUE_CLOUD_MENU_PAYLOAD_READ = 'venueCloudMenuPayload:read',
  VENUE_POS_INGESTION_READ = 'venuePOSIngestion:read',
  VENUE_DATA_RESYNC_CREATE = 'venueDataResync:create',
  VENUE_GE_PROVISIONING_UPDATE = 'venueGEProvisioning:update',
  VENUE_PAYMENT_SETTING_READ = 'venuePaymentSetting:read',
  CORPORATION_DIAGNOSTIC_READ = 'corporationDiagnostic:read',
  CORPORATION_STAFF_MEMBERS_READ = 'corporationStaffMembers:read',
  CORPORATION_VENUE_LIST_READ = 'corporationVenueList:read',
  CORPORATION_GUEST_DATA_IMPORT_READ = 'corporationGuestDataImport:read',
  CORPORATION_GROUP_MANAGEMENT_CREATE = 'corporationGroupManagement:create',
  CORPORATION_DATA_TRANSFER_CREATE = 'corporationDataTransfer:create',
  DIGITAL_GIFT_CARD_SUPPORT_UPDATE = 'digitalGiftCardSupport:update',
  AGENT_PERMISSION_MANAGER = 'agentPermissionManager:update',
  BILL_PROCESSOR_ENTITLEMENT_UPDATE = 'billProcessorEntitlement:update',
}

export enum Entitlement {
  MENU_ENTITY = 'urn:touchbistro:service:menuservice',
  FLOORPLAN_MENU_ENTITLEMENT = 'urn:touchbistro:apps:venue:pos/cloud/floorplan',
  ONLINE_ORDERING = 'urn:touchbistro:apps:venue:pos/integrations/ordering',
  OO_AUTO_ACCEPT = 'urn:touchbistro:service:oo-auto-accept',
  REMOTE_MENU_MANAGEMENT = 'urn:touchbistro:service:remotemenumanagement',
  AGENT_ACCESS = 'urn:group:venue:owners',
  LOYALTY = 'urn:touchbistro:apps:venue:pos/integrations/loyalty',
  GIFTCARDS = 'urn:touchbistro:apps:venue:pos/integrations/giftcard',
  MARKETING = 'urn:touchbistro:loyalty/packages/tb-marketing',
  PAYMENTS_WEPAY_CP = 'urn:touchbistro:payments/chase/wepay',
  PAYMENTS_WEPAY_CNP = 'urn:touchbistro:payments:ecom/chase/wepay',
  PAYMENTS_PAYRIX_CP = 'urn:touchbistro:payments/fis/payrix',
  PAYMENTS_PAYRIX_CNP = 'urn:touchbistro:payments:ecom/fis/payrix',
  PAYMENTS_WORLDPAY_CP = 'urn:touchbistro:payments/referral/worldpay',
  PAYMENTS_WORLDPAY_CNP = 'urn:touchbistro:payments:ecom/referral/worldpay',
  PAYMENTS_CHASE_INGENICO = 'urn:touchbistro:payments/chase/ingenico',
  PAYMENTS_MONERIS_GO_CP = 'urn:touchbistro:payments/partners/moneris/go',
  PAYMENTS_CHASE_PAX_CP = 'urn:touchbistro:payments/chase/pax',
  SIMPLIFIED_MENU = 'urn:touchbistro:service:simplifiedmenu',
  RECEIPT_SETTINGS = 'urn:touchbistro:service:receiptsettings',
  ORDER_SETTINGS = 'urn:touchbistro:service:posordersettings',
  RESERVATIONS = 'urn:touchbistro:apps:venue:pos/integrations/reservations',
  CLOUD_REPORTS = 'urn:touchbistro:service:cloud-reports',
  POS_STAFF = 'urn:touchbistro:service:pos/staff',
  CONFIGURABLE_REPORTS = 'urn:touchbistro:service:configurable-reports',
  TB_INVENTORY = 'urn:touchbistro:service:inventory',
  TB_KDS = 'urn:touchbistro:service:kds',
  ANDROID_DEVICE = 'urn:touchbistro:service:android-device',
  DOORDASH_MARKETPLACE = 'urn:touchbistro:service:doordash-marketplace',
  SCHEDULE_SALES_SUMMARY_PDF_REPORT = 'urn:touchbistro:service:schedule-sales-summary-pdf-report',
  TB_LABOR_MANAGEMENT = 'urn:touchbistro:service:labor',
  DISABLE_POS_INVENTORY = 'urn:touchbistro:apps:venue:pos/feature/disable-pos-inventory',
  BILL_PROCESSOR = 'urn:touchbistro:service:billprocessor',
}

export enum EntitlementPropertyXRefID {
  PAYMENTS_RATES_PER_TRANSACTION = 'urn:touchbistro:payments/rates/pertxn',
  PAYMENTS_RATES_DISCOUNT = 'urn:touchbistro:payments/rates/discount',
  PAYMENTS_PRICING_MODEL = 'urn:touchbistro:payments/pricing/pricingmodel',
  LOYALTY_PROVIDER_ECARD = 'urn:touchbistro:apps:venue:pos/integrations/loyalty/providers/ecard',
  LOYALTY_PROVIDER_TABLEUP = 'urn:touchbistro:apps:venue:pos/integrations/loyalty/providers/tableup',
  GIFTCARD_PROVIDER_TABLEUP = 'urn:touchbistro:apps:venue:pos/integrations/giftcard/providers/tableup',
  MARKETING_PROVIDER_TABLEUP = 'urn:touchbistro:apps:venue:pos/integrations/marketing/providers/tableup',
  RESERVATIONS_CLOUD_ADMIN_SETTINGS = 'urn:touchbistro:reservations/cloudadminsettings',
  MENU_EASY_SALES_CATEGORY_MAPPING = 'urn:touchbistro:service:simplifiedmenu/ai/sales-categories',
}

export type NonEmptyArray<T> = [T, ...T[]]

// export interface MenuInputtingFeatureFlagProvider {
//   variation: (
//     flagKey: string,
//     user?: {
//       key: string
//       anonymous: boolean;
//       custom?: {
//         [key: string]: string | number | boolean | (string | number | boolean)[];
//       }
//     },
//     defaultValue?:boolean
//   ) => boolean
// }

/**
 * hasPermissions checks to use if a user has the required permissions.
 * @example
 * hasPermissions({
 *  isAgent:agent.isAgent,
 *  userVenuePermissions,
 *  wantPermissions: {
 *    [Resource.MENU]: [Operation.READ, Operation.UPDATE],
 *    [Resource.TAX]: [Operation.CREATE],
 *  },
 * })
 */
export function hasPermissions({
  isAgent = false,
  userBasePermissions,
  wantPermissions,
  requireAll = true,
}: {
  /** Optional, is the session user an agent? Defaults to `false`. */
  isAgent?: boolean
  /** The permissions the user has. */
  userBasePermissions: PermissionMap
  /** The permissions that the user is required to have. */
  wantPermissions: Record<string, NonEmptyArray<Operation>>
  /**
   * Optional, sets whether to require all permissions exist for the user and venue
   * or only one. Defaults to `true`.
   */
  requireAll?: boolean
}): boolean {
  if (isAgent) {
    return true
  }

  // Handle permissions OR style. If at least one exists, permission is granted.
  if (!requireAll) {
    for (const [wantResource, wantOps] of Object.entries(wantPermissions)) {
      const ops = userBasePermissions[wantResource]
      if (ops === undefined) {
        continue
      }

      for (const o of wantOps) {
        if (ops.includes(o)) {
          return true
        }
      }
    }
    return false
  }

  // Handle permissions AND style. If at least one does not exist, permission is denied.
  for (const [wantResource, wantOps] of Object.entries(wantPermissions)) {
    const ops = userBasePermissions[wantResource]
    if (ops === undefined) {
      return false
    }

    for (const o of wantOps) {
      if (!ops.includes(o)) {
        return false
      }
    }
  }
  return true
}

/**
 * Checks if the venue has the entitlement that allows them access to the payment portal.
 */
export function venueHasPaymentsEnabled(venueEntitlements: Entitlement[] | undefined): boolean {
  const entitlements = venueEntitlements ?? []
  const controllerEntitlements = [
    Entitlement.PAYMENTS_WEPAY_CP as string,
    Entitlement.PAYMENTS_WEPAY_CNP as string,
  ]

  return entitlements.some((entitlement) => controllerEntitlements.includes(entitlement))
}

/**
 * @param entitlements list of entitlements from a venue
 * @param isAgent
 */
export const isAgentMenuInputEnabled = (
  entitlements: Entitlement[],
  isAgent: boolean,
  isMimicking: boolean
): boolean => {
  // regular users should not be able to access related resources
  // agents should be able to access related resources during a mimic session
  if (isMimicking) {
    return false
  } else {
    return entitlements.includes(Entitlement.MENU_ENTITY) && !isAgent
  }
}

/**
 * Checks if the venue has the entitlement that allows them access to the receipt settings
 * urn:touchbistro:service:receiptsettings
 */
export const venueHasReceiptSettingsEnabled = (entitlements: Entitlement[]): boolean => {
  return entitlements.includes(Entitlement.RECEIPT_SETTINGS)
}

/**
 * Checks if the base has the entitlement that allows them access to the invenue pos staff content
 * urn:touchbistro:service:receiptsettings
 */
export const baseHasInvenueStaffEnabled = (entitlements: Entitlement[]): boolean => {
  return entitlements.includes(Entitlement.POS_STAFF)
}

export const venueHasAndroidDeviceEnabled = (entitlements: Entitlement[]): boolean => {
  return entitlements.includes(Entitlement.ANDROID_DEVICE)
}

export const venueHasOrderSettingsEnabled = (entitlements: Entitlement[]): boolean => {
  return entitlements.includes(Entitlement.ORDER_SETTINGS)
}
